import {buildCollection, Permissions, Properties} from "@firecms/core";
import {locales} from "./locales";

type SurveyQuestionTranslation = {
    question: string,
    hint: string
}

type SurveyQuestion = {
    question_key: string,
    type: "text" | "number" | "pain-slider" | "pain-slider-alternate",
    validation?: {
        min?: number,
        max?: number,
    },
    mandatory?: boolean,
    translations: Record<string, SurveyQuestionTranslation>
}

type Survey = {
    survey_key: string,
    description?: string,
    image?: string
    questions: Array<SurveyQuestion>
}

const surveyQuestionTranslationProperties = {
    question: {
        name: "Question",
        dataType: "string"
    },
    hint: {
        name: "Hint",
        dataType: "string"
    }
};


const surveyQuestionProperties = Object.keys(locales).map((locale: string) => ({
    [locale]: {
        name: Object.values(locales)[Object.keys(locales).indexOf(locale)],
        dataType: "map",
        expanded: false,
        disabled: false,
        properties: {
            question: {
                name: "Question",
                dataType: "string"
            },
            hint: {
                name: "Hint",
                dataType: "string"
            },
            minimum_value_label: {
                name: "Minimum value label",
                dataType: "string"
            },
            maximum_value_label: {
                name: "Maximum value label",
                dataType: "string"
            }
        }
    }
})).reduce((acc, val) => ({...acc, ...val}), {}) as Properties;


export function buildSurveysCollection() {


    return buildCollection<Survey>({
        id: "surveys",
        path: "surveys",
        name: "Surveys",
        singularName: "Survey",
        properties: {
            survey_key: {
                name: "The key of the survey",
                dataType: "string",
                validation: { required: true }
            },
            description: {
                name: "Description",
                dataType: "string"
            },
            image: {
                name: "Image",
                dataType: "string",
                storage: {
                    storagePath: "images",
                    acceptedFiles: ["image/*"]
                }
            },
            questions: {
                name: "Questions",
                dataType: "array",
                // @ts-ignore
                of: {
                    dataType: "map",
                    name: "Question",
                    expanded: false,
                    previewProperties: ["question_key"],
                    properties: {
                        question_key: {
                            name: "The key of the question",
                            dataType: "string",
                            validation: {required: true}
                        },
                        type: {
                            name: "Input Type",
                            description: "The type of the input, open text or number",
                            dataType: "string",
                            enumValues: {
                                "text": "Text",
                                "number": "Number",
                                "pain-slider": "Pain Slider",
                                "pain-slider-alternate": "Pain Slider (alternate)"
                            }
                        },
                        mandatory: {
                            name: "Mandatory",
                            description: "If the question is mandatory",
                            dataType: "boolean"
                        },
                        validation: {
                            name: "Validation for number input",
                            description: "Validation for number input questions",
                            expanded: false,
                            dataType: "map",
                            properties: {
                                min: {
                                    name: "Minimum value",
                                    dataType: "number"
                                },
                                max: {
                                    name: "Maximum value",
                                    dataType: "number"
                                }
                            }
                        },
                        translations: {
                            name: "Translations",
                            expanded: false,
                            dataType: "map",
                            properties: surveyQuestionProperties
                        }
                    }
                }
            }
        }
    });
}

