import {
    buildCollection,
    buildEnumValueConfig,
    buildProperty,
    Entity,
    EnumValues,
    FireCMSContext,
    Permissions
} from "@firecms/core";
import { exerciseTypes } from "./exercises";
import BodyPartsField from "./fields/body/BodyPartsField";
import BodyPartsPreview from "./fields/body/BodyPartsPreview";
import { CopyMovementsAction } from "./actions/CopyMovementsAction";
import { bodyPartsEnum } from "./fields/body/body_parts";
import { exerciseSubtypes } from "./exercise_collection";

function buildCustomersRestrictions() {
    let forbiddenJointsCollection = buildCollection({
        name: "Restricted joint movements",
        id: "restricted_joint_movements",
        path: "restricted_joint_movements",
        singularName: "Restricted joint movement",
        properties: {
            joint: {
                name: "Joint",
                description: "Select the joint for which movement directions are to be restricted",
                dataType: "reference",
                path: "medico/v2.0.0/joints"
            },
            related_movements: ({ values }) => (
                {
                    dataType: "array",
                    disabled: !values.joint,
                    name: "Related movements",
                    of: {
                        dataType: "map",
                        properties: {
                            movement: {
                                name: "Movement",
                                description: "Select the movement direction of the joint to be restricted",
                                dataType: "reference",
                                // @ts-ignore
                                path: values.joint?.path ? values.joint.path + "/" + values.joint.id + "/movements" : undefined,
                                validation: {
                                    required: true,
                                    requiredMessage: 'you need to choose a movement direction. If you want to restrict the joint in general, you can do this via the restrictions tab.'
                                }
                            },
                            maximal_allowed_extent: {
                                name: "Maximal allowed extent",
                                dataType: "number",
                                description: "Enter the degree to which the movement may still be performed. If the movement is generally restricted, do not enter anything."
                            }
                        }
                    }
                }),
        },
        additionalFields: [{
            key: "copy_movements",
            name: "Copy to mirror",
            dependencies: ["joint", "related_movements"],
            Builder: ({ entity, context }: {
                entity: Entity<any>;
                context: FireCMSContext;
            }) => {
                return <CopyMovementsAction entity={entity}
                                            context={context}/>;
            }
        }]
    });
    return buildCollection({
        id: "restrictions",
        path: "restrictions",
        defaultSize: "s",
        // permissions: permissions,
        name: "Restrictions",
        properties: {
            restriction_start_date: {
                name: "Restriction start date",
                validation: { required: true },
                dataType: "date",
                mode: "date"
            },
            restriction_duration: {
                name: "Restriction duration",
                validation: {
                    min: 0,
                    integer: true,
                    required: true
                },
                dataType: "number",
                description: "Restriction duration in weeks"
            },
            restricted_exercises: {
                name: "Restricted exercises",
                dataType: "array",
                of: {
                    name: "Exercises",
                    dataType: "reference",
                    path: "exercises"
                }
            },
            restricted_exercise_types: {
                name: "Restricted exercise types",
                dataType: "array",
                of: {
                    dataType: "string",
                    enumValues: exerciseTypes
                }
            },
            restricted_exercise_subtypes: {
                name: "Restricted exercise subtypes",
                dataType: "array",
                of: {
                    dataType: "string",
                    enumValues: exerciseSubtypes
                }
            },
            restricted_main_and_side_effect_body_parts: {
                name: "Restricted main and side effect body parts",
                dataType: "array",
                Field: BodyPartsField,
                customProps: {
                    mapped: false,
                    mirroring: true,
                    multiSelect: true
                },
                Preview: BodyPartsPreview,
                description:
                    "An exercise has one main effects (exercise type) on different parts of the body simultaneously.",
                of: {
                    dataType: "string",
                    enumValues: bodyPartsEnum
                }
            },
            restricted_main_effect_body_parts: {
                name: "Restricted main effect body parts",
                dataType: "array",
                Field: BodyPartsField,
                customProps: {
                    mapped: false,
                    mirroring: true,
                    multiSelect: true
                },
                Preview: BodyPartsPreview,
                description:
                    "An exercise has one main effects (exercise type) on different parts of the body simultaneously.",
                of: {
                    dataType: "string",
                    enumValues: bodyPartsEnum
                }
            },
            restricted_starting_positions: {
                name: "Restricted starting positions",
                dataType: "array",
                of: {
                    name: "Starting position",
                    dataType: "reference",
                    path: "starting_positions"
                }
            },
            restricted_loaded_body_parts: {
                name: "Restricted loaded body parts",
                dataType: "array",
                Field: BodyPartsField,
                customProps: {
                    mapped: false,
                    mirroring: true,
                    multiSelect: true
                },
                Preview: BodyPartsPreview,
                description:
                    "An exercise has one main effects (exercise type) on different parts of the body simultaneously.",
                of: {
                    dataType: "string",
                    enumValues: bodyPartsEnum
                }
            },
            restricted_joints: {
                name: "Restricted joints",
                dataType: "array",
                of: {
                    name: "Joint",
                    dataType: "reference",
                    path: "medico/v2.0.0/joints"
                }
            },
            comments: {
                name: "Comments",
                dataType: "string"
            },
            problem_description: {
                name: "Problem description",
                dataType: "string"
            },
            responsibilities: {
                name: "Responsibilities",
                dataType: "reference",
                path: "admins"
            },
            created_at: buildProperty({
                dataType: "date",
                name: "Created At",
                autoValue: "on_create"
            }),
            last_update: {
                dataType: "date",
                name: "Last update",
                autoValue: "on_update"
            },
        }
        ,
        singularName: "Restriction",
        subcollections: [
            forbiddenJointsCollection
        ]
    });
}

export function buildCustomersCollection() {

    return buildCollection({
        id: "customer",
        path: "customer",
        name: "Customers",
        singularName: "Customer",
        icon: "Person",
        initialSort: ["created_at", "desc"],
        customId: true,
        properties: {
            created_at: {
                dataType: "date",
                name: "Created At",
            },
            active: {
                dataType: "boolean",
                name: "Active",
                description: "Whether the customer is active or not",
                readOnly: true,
            },
            analytics_id: {
                dataType: "string",
                name: "Analytics ID",
                description: "Analytics ID",
                readOnly: true,
            },
            customer_type: {
                name: "Customer type",
                dataType: "string",
                readOnly: true,
            },
            goal: {
                name: "Goal",
                dataType: "string",
                readOnly: true,
            },
            work_mode: {
                name: "Work mode",
                dataType: "string",
                readOnly: true,

            },
            pain_info: {
                name: "Pain Info",
                dataType: "map",
                readOnly: true,
                keyValue: true
            },
            registration_context: {
                name: "Registration context",
                dataType: "map",
                readOnly: true,
                keyValue: true
            },
            current_diagnosis: {
                name: "Current diagnosis",
                dataType: "array",
                of: {
                    dataType: "string",
                    name: "Diagnosis",
                    description: "Diagnosis",
                    readOnly: true,
                },
                readOnly: true,
            },
            past_diagnosis: {
                name: "Past diagnosis",
                dataType: "array",
                of: {
                    dataType: "string",
                    name: "Diagnosis",
                    description: "Diagnosis",
                    readOnly: true,
                },
                readOnly: true,
            },
            send_notifications: {
                dataType: "boolean",
                name: "Send notification",
                readOnly: true,
            },
            number_of_exercises: {
                dataType: "number",
                name: "Number of exercises",
                readOnly: true,

            }
        },
        group: "Core",
        textSearchEnabled: true,
        // permissions: permissions,
        subcollections: [
            buildCustomersRestrictions(),
            buildCustomersRecommendations(),
        ]
    });
}


function buildCustomersRecommendations() {
    return buildCollection({
        id: "recommendations",
        path: "recommendations",
        defaultSize: "s",
        name: "Recommendations",
        properties: {
            recommendation_start_date: {
                name: "Recommendation start date",
                validation: { required: true },
                dataType: "date",
                mode: "date"
            },
            recommendation_duration: {
                name: "Recommendation duration",
                validation: {
                    min: 0,
                    integer: true,
                    required: true
                },
                dataType: "number",
                description: "Recommendation duration in weeks"
            },
            exercise_pool_recommendation: {
                name: "Exercise pool recommendation",
                dataType: "map",
                properties: {
                    exercise_pool: {
                        name: "Exercise pool",
                        dataType: "array",
                        of: {
                            name: "Exercises",
                            dataType: "reference",
                            path: "exercises"
                        },
                        description: "Exercises which form the pool of exercises from which exercises are drawn randomly for the special recommendation"
                    },
                    appearances: {
                        name: "Appearances",
                        dataType: "number",
                        description: "How many times should exercises be selected by the special recommendation in the given interval",
                        validation: {
                            min: 1,
                            integer: true
                        }
                    },
                    interval: {
                        name: "Interval",
                        dataType: "number",
                        description: "Number of days for which the given appearances parameter is valid",
                        validation: {
                            min: 1,
                            integer: true
                        }
                    },
                    limit_per_day: {
                        name: "Limit per day",
                        dataType: "array",
                        'description': "additional limits per day which can be depending on the total number of exercises the user chose in the app for the day",
                        of: {
                            dataType: "map",
                            properties: {
                                min_number_exercises: {
                                    name: "Minimal number of exercises",
                                    dataType: "number",
                                    description: "Minimal total number of exercise on the day for which the limit should hold",
                                    validation: {
                                        min: 1,
                                        integer: true
                                    }
                                },
                                max_number_exercises: {
                                    name: "Maximal number of exercises",
                                    dataType: "number",
                                    description: "Maximal total number of exercise on the day for which the limit should hold",
                                    validation: {
                                        min: 1,
                                        integer: true
                                    }
                                },
                                limit: {
                                    name: "Limit",
                                    dataType: "number",
                                    description: "Maximal number of exercises selected by special recommendation from the given exercise pool",
                                    validation: {
                                        min: 0,
                                        integer: true
                                    }
                                },
                            }
                        }
                    }
                }
            },

            additionally_allowed_exercise_types: {
                name: "Additionally allowed exercise types",
                dataType: "array",
                description: "exercise types that are allowed for this user additionally to those exercise types which are allowed by medico",
                of: {
                    dataType: "string",
                    enumValues: exerciseTypes
                }
            },
            recommended_main_pain_area: {
                name: "Recommended forced main pain area",
                dataType: "string",
                description: "main pain area that will be chosen if(!) the user has entered it as a pain area (otherwise medico chooses the main pain area on its own)",
                enumValues: bodyPartsEnum,
                columnWidth: 300,
                Field: BodyPartsField,
                customProps: {
                    mapped: false,
                    multiSelect: false
                },
                Preview: BodyPartsPreview
            },
            comments: {
                name: "Comments",
                dataType: "string"
            },
            problem_description: {
                name: "Problem description",
                dataType: "string"
            },
            responsibilities: {
                name: "Responsibilities",
                dataType: "reference",
                path: "admins"
            },
            created_at: buildProperty({
                dataType: "date",
                name: "Created At",
                autoValue: "on_create"
            }),
            last_update: {
                dataType: "date",
                name: "Last update",
                autoValue: "on_update"
            },
        }
        ,
        singularName: "Recommendation",
        subcollections: []
    });
}


const generalRestrictionType: EnumValues = {
    absolute: buildEnumValueConfig({
        id: "absolute",
        label: "Absolute",
        color: "blueDark"
    }),
    relative: buildEnumValueConfig({
        id: "relative",
        label: "Relative",
        color: "greenLighter"
    })
};

const diseaseStateTypes: EnumValues = {
    acute: buildEnumValueConfig({
        id: "acute",
        label: "Acute"
    }),
    subacute: buildEnumValueConfig({
        id: "subacute",
        label: "Subacute"
    }),
    chronic: buildEnumValueConfig({
        id: "chronic",
        label: "Chronic"
    })
};
